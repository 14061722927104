/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ RESET ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
}

figure {
    margin: 0;
}

ul,
li {
    margin: 0;
    padding: 0;
}

button {
    margin: 0;
    padding: 0;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ RESET FIM ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

$sm: 540;
$md: 720;
$lg: 960;
$xl: 1140;
@mixin MQ($sizeMin, $sizeMax) {
    @if $sizeMin !=0 and $sizeMax !=0 {
        @media screen and (min-width: $sizeMin + 'px') and (max-width: $sizeMax + 'px') {
            @content;
        }
    } @else if $sizeMin !=0 {
        @media screen and (min-width: $sizeMin + 'px') {
            @content;
        }
    } @else if $sizeMax !=0 {
        @media screen and (max-width: $sizeMax + 'px') {
            @content;
        }
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ VARIÁVEIS ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

$main: #442769;
$mainHover: #5d4679;
$subMain: #9b26af;
$subMainHover: #a23db3;

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ FONTS ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

// @font-face {
//     font-family: 'OpenSansBold';
//     src: url('../font/OpenSans-Bold.eot');
//     src: url('../font/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../font/OpenSans-Bold.woff2') format('woff2'),
//         url('../font/OpenSans-Bold.woff') format('woff'),
//         url('../font/OpenSans-Bold.ttf') format('truetype'),
//         url('../font/OpenSans-Bold.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'OpenSansBold';
//     src: url('../font/OpenSans-Bold.eot');
//     src: url('../font/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../font/OpenSans-Bold.woff2') format('woff2'),
//         url('../font/OpenSans-Bold.woff') format('woff'),
//         url('../font/OpenSans-Bold.ttf') format('truetype'),
//         url('../font/OpenSans-Bold.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'FontAwesome';
//     src: url('../lib/fontawesome/webfonts/fa-regular-400.eot');
//     src: url('../lib/fontawesome/webfonts/fa-regular-400.eot?#iefix') format('embedded-opentype'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.woff2') format('woff2'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.woff') format('woff'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.ttf') format('truetype'),
//         url('../lib/fontawesome/webfonts/fa-regular-400.svg') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ GENERAL ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

body,
html {
    overflow-x: hidden;
    min-height: 100%;
}

body {
    background: url('../assets/images/background4.png'),
        url('../assets/images/background5.png');
    background-position:
        left top,
        left top;
    background-repeat: repeat-x, repeat;
}

body {
    //font-family: Verdana, Geneva, sans-serif;
    font-family: 'Open Sans', sans-serif;
    line-height: 24px;
    font-size: 16px;
    color: #4a4a4a;
}

body,
input {
    font-weight: normal;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

::selection {
    background: #333;
    color: #fff;
    text-shadow: none;
}

div {
    /*border: 1px solid yellow; temp: just to develop*/
}

// h1,h2,h3,h4,h5,h6,p {
//    font-family: inherit;
//    font-size: inherit;
// }
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

header,
main {
    max-width: 1440px;
    margin: auto;
}

main {
    flex: 1;
    flex-direction: row;
    display: flex;
    width: 90%;
    z-index: 1;
    section {
        width: 80%;
        padding-right: 20px;
    }
    nav {
        width: 20%;
    }
    @include MQ(0, $lg) {
        flex-direction: column-reverse;
        section,
        nav {
            width: 100%;
            padding-right: 0;
        }
    }
}

footer {
    .container {
        max-width: 90%;
        margin: auto;
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ BACKGROUND ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.background {
    max-width: 1440px;
    width: 90%;
    height: 100%;
    position: absolute;
    left: 50%;
    .background-container {
        position: relative;
        left: -50%;
        width: 100%;
        height: 100%;
        .lamp {
            width: 100%;
            max-width: 50px;
            z-index: 100;
            position: absolute;
            right: -4%;
            // max-width: 500px;
            // right: 50%;
            cursor: pointer;
            display: inline-block;
            transform-origin: top center;
            transform: rotate(45deg);
            animation: lamp 3s forwards;
            .bulb {
                fill-opacity: 0.1;
                animation: bulb 0.3s 0.3s 5
                    cubic-bezier(0.26, 1.17, 0.89, -0.74) alternate forwards;
                &-on {
                    fill: #ffef13 !important;
                }
                &-off {
                    fill: #ffffff !important; //b1b1b1
                }
            }
            .switch {
                transition: transform 0.3s;
                &:active {
                    transform: translateY(5px);
                }
            }
            @keyframes bulb {
                to {
                    fill-opacity: 1;
                    fill: #ffef13;
                }
            }
            @keyframes lamp {
                5% {
                    transform: rotate(-45deg);
                }
                10% {
                    transform: rotate(35deg);
                }
                15% {
                    transform: rotate(-35deg);
                }
                25% {
                    transform: rotate(15deg);
                }
                40% {
                    transform: rotate(-15deg);
                }
                65% {
                    transform: rotate(3deg);
                }
                85% {
                    transform: rotate(-1deg);
                }
                100% {
                    transform: rotate(0deg);
                }
            }
            .lamp-light {
                fill-opacity: 0.1;
                -webkit-animation: lamp-light-animation ease-in 1;
                -moz-animation: lamp-light-animation ease-in 1;
                animation: lamp-light-animation ease-in 1;
                -webkit-animation-delay: 1s;
                -moz-animation-delay: 1s;
                animation-delay: 1s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
                -webkit-animation-duration: 2s;
                -moz-animation-duration: 2s;
                animation-duration: 2s;
                &-off {
                    display: none;
                }
            }
            @keyframes lamp-light-animation {
                to {
                    fill-opacity: 1;
                }
            }
            .style1 {
                fill: none;
                stroke: #d7d5af;
                stroke-width: 0.263;
                stroke-linecap: round;
                stroke-miterlimit: 10;
            }
            .style2 {
                fill: none;
                stroke: #d7d5af;
                stroke-width: 0.263;
                stroke-linecap: round;
                stroke-miterlimit: 10;
            }
            .style3 {
                fill: none;
                stroke: #d7d5af;
                stroke-width: 0.263;
                stroke-linecap: round;
                stroke-miterlimit: 10;
            }
            .style4 {
                fill: #2d2d2f;
            }
        }
        .background-border-left-top {
            position: absolute;
            left: -140px;
            width: 240px;
        }
        .background-middle-right {
            position: absolute;
            right: -270px;
            top: 30%;
            z-index: -1;
        }
        .background-circle {
            background-color: rgba(199, 213, 223, 48%);
            height: 400px;
            width: 400px;
            position: absolute;
            top: 50%;
            left: -5%;
            border-radius: 100%;
            z-index: -1;
            &:after {
                content: '';
                border: 2px solid rgba(128, 128, 128, 0.5);
                top: 4%;
                left: 4%;
                background-color: transparent;
                display: block;
                height: 400px;
                width: 400px;
                border-radius: 100%;
                position: relative;
                @extend .in-animate-circle;
            }
        }
        .background-square {
            background-color: rgba(199, 213, 223, 48%);
            height: 200px;
            width: 200px;
            position: absolute;
            top: 95%;
            right: 10%;
            border-radius: 6px;
            &:after {
                content: '';
                border: 2px solid rgba(128, 128, 128, 0.5);
                top: 4%;
                left: 4%;
                background-color: transparent;
                display: block;
                height: 200px;
                width: 200px;
                border-radius: 6px;
                position: relative;
                @extend .in-animate-square;
            }
        }
    }
}

@-webkit-keyframes effectCircle {
    0% {
        left: -5%;
    }
    50% {
        left: -10%;
    }
    100% {
        left: -5%;
    }
}

@-moz-keyframes effectCircle {
    0% {
        left: -5%;
    }
    50% {
        left: -10%;
    }
    100% {
        left: -5%;
    }
}

@keyframes effectCircle {
    0% {
        left: -5%;
    }
    50% {
        left: -10%;
    }
    100% {
        left: -5%;
    }
}

@-webkit-keyframes effectCircleY {
    0% {
        top: 40%;
    }
    50% {
        top: 30%;
    }
    100% {
        top: 40%;
    }
}

@-moz-keyframes effectCircleY {
    0% {
        top: 40%;
    }
    50% {
        top: 30%;
    }
    100% {
        top: 40%;
    }
}

@keyframes effectCircleY {
    0% {
        top: 40%;
    }
    50% {
        top: 30%;
    }
    100% {
        top: 40%;
    }
}

.animate-circle {
    -webkit-animation:
        effectCircle ease infinite alternate,
        effectCircleY ease infinite alternate;
    -moz-animation:
        effectCircle ease infinite alternate,
        effectCircleY ease infinite alternate;
    animation:
        effectCircle ease infinite alternate,
        effectCircleY ease infinite alternate;
    -webkit-animation-duration: 10s, 10s;
    -moz-animation-duration: 10s, 10s;
    animation-duration: 10s, 10s;
}

// @-webkit-keyframes effectCircleIn {
//     0% {
//         left: -5%;
//     }
//     50% {
//         left: -10%
//     }
//     100% {
//         left: -5%;
//     }
// }
// @-moz-keyframes effectCircleIn {
//     0% {
//         left: -5%;
//     }
//     50% {
//         left: -10%
//     }
//     100% {
//         left: -5%;
//     }
// }
@keyframes effectCircleIn {
    0% {
        //transform: rotateZ(60deg) translate(-5em, 7.5em);
        left: 4%;
    }
    50% {
        left: 2%;
    }
    100% {
        left: 7%;
    }
}

.in-animate-circle {
    -webkit-animation: effectCircleIn ease infinite alternate;
    -moz-animation: effectCircleIn ease infinite alternate;
    animation: effectCircleIn ease infinite alternate;
    -webkit-animation-duration: 10s, 10s;
    -moz-animation-duration: 10s, 10s;
    animation-duration: 10s, 10s;
}

// @-webkit-keyframes effectSquareIn {
//     0% {
//         left: -5%;
//     }
//     50% {
//         left: -10%
//     }
//     100% {
//         left: -5%;
//     }
// }
// @-moz-keyframes effectSquareIn {
//     0% {
//         left: -5%;
//     }
//     50% {
//         left: -10%
//     }
//     100% {
//         left: -5%;
//     }
// }
@keyframes effectSquareIn {
    0% {
        left: 4%;
        transform: rotate(0);
    }
    40% {
        left: 20%;
        transform: rotate(100deg);
    }
    60% {
        left: 20%;
        transform: rotate(120deg);
    }
    100% {
        left: 4%;
        transform: rotate(0deg);
    }
}

.in-animate-square {
    -webkit-animation: effectSquareIn cubic-bezier(0.17, 1.07, 0.92, 0.06)
        infinite alternate;
    -moz-animation: effectSquareIn cubic-bezier(0.17, 1.07, 0.92, 0.06) infinite
        alternate;
    animation: effectSquareIn cubic-bezier(0.17, 1.07, 0.92, 0.06) infinite
        alternate;
    -webkit-animation-duration: 15s;
    -moz-animation-duration: 15s;
    animation-duration: 15s;
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    animation-delay: 3s;
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ HEADER ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

header {
    width: 90%;
    padding-top: 70px;
    .logo {
        display: flex;
        padding-left: 15px;
        padding-top: 10px;
        position: absolute;
        flex-direction: column;
        @include MQ(0, $lg) {
            text-align: center;
            position: relative;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;
        }
        & > div {
            display: flex;
            flex-direction: row;
        }
        div.letter {
            border: 7px solid #000000;
            border-radius: 15px;
            padding: 28px 18px;
            font-size: 3.6rem;
            display: flex;
            justify-content: center;
            align-content: center;
            margin-right: 7px;
            background-color: #4888ac;
            color: #000;
            font-weight: 700;
            min-width: 95px;
        }
        div.letter:last-child {
            background-color: #fff;
        }
        div.subtitle {
            left: 10%;
            position: relative;
            font-family: 'Italianno', cursive;
            font-size: 2.5rem;
            line-height: 2rem;
            margin-top: 10px;
            @include MQ(0, $lg) {
                position: unset;
            }
        }
    }
    .name {
        width: 100%;
        float: left;
        div {
            background-color: #4888ac;
            color: #fff;
            font-weight: bold;
            padding-left: 10px;
            padding-bottom: 2px;
            width: 50%;
            float: right;
            -webkit-box-shadow: -5px 5px 0px rgba(0, 0, 0, 1);
            -moz-box-shadow: -5px 5px 0px rgba(0, 0, 0, 1);
            box-shadow: -5px 5px 0px rgba(0, 0, 0, 1);
            border-radius: 3px;
            border-bottom-right-radius: 0;
        }
    }
    .me-desktop {
        width: 185px;
        height: 185px;
        float: right;
        position: relative;
        top: 20px;
        border: 7px solid #4888ac;
        border-radius: 50%;
        overflow: hidden;
        background-image: url('../assets/images/me_vector2.jpg');
        background-repeat: no-repeat;
        background-position: 0% 0%;
        @include MQ(0, $lg) {
            float: none;
            margin: auto;
            margin-bottom: 40px;
        }
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ MENU ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

@font-face {
    font-family: 'WebSymbolsLigaRegular';
    src: url('../assets/fonts/websymbolsligaregular/websymbolsligaregular.eot');
    src:
        url('../assets/fonts/websymbolsligaregular/websymbolsligaregular.eot?#iefix')
            format('embedded-opentype'),
        url('../assets/fonts/websymbolsligaregular/websymbolsligaregular.woff')
            format('woff'),
        url('../assets/fonts/websymbolsligaregular/websymbolsligaregular.ttf')
            format('truetype'),
        url('../assets/fonts/websymbolsligaregular/websymbolsligaregular.svg#WebSymbolsRegular')
            format('svg');
    font-weight: normal;
    font-style: normal;
}

#menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    height: 0;
    -webkit-transition: height 0.1s ease-in;
    -moz-transition: height 0.1s ease-in;
    -o-transition: height 0.1s ease-in;
    -ms-transition: height 0.1s ease-in;
    transition: height 0.1s ease-in;
    li {
        flex: 1;
        display: flex;
        text-align: center;
        a {
            flex: 1;
            cursor: pointer;
        }
    }
    @include MQ(0, $lg) {
        &.open {
            height: 250px !important;
        }
        li {
            a {
                flex: 1;
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-start;
                color: #efefef;
                -webkit-transition: all 300ms linear;
                -moz-transition: all 300ms linear;
                -o-transition: all 300ms linear;
                -ms-transition: all 300ms linear;
                transition: all 300ms linear;
                overflow: hidden;
                &:hover {
                    background: #4888ac !important;
                    color: #000;
                    .ca-icon {
                        -webkit-transition: all 300ms linear;
                        -moz-transition: all 300ms linear;
                        -o-transition: all 300ms linear;
                        -ms-transition: all 300ms linear;
                        transition: all 300ms linear;
                        color: #000;
                        font-size: 120px;
                        opacity: 0.8;
                    }
                    .ca-sub {
                        color: #191919;
                        opacity: 1;
                    }
                }
                & > span {
                    -webkit-transition: all 300ms linear;
                    -moz-transition: all 300ms linear;
                    -o-transition: all 300ms linear;
                    -ms-transition: all 300ms linear;
                    transition: all 300ms linear;
                    position: absolute;
                    top: 10%;
                    font-family: 'WebSymbolsLigaRegular', cursive;
                    font-size: 1.5rem;
                }
                .ca-main {
                    font-weight: bold;
                    position: relative;
                    top: 5px;
                }
                .ca-sub {
                    color: #cecece;
                    font-size: 1rem;
                    font-weight: normal;
                    opacity: 0.8;
                    line-height: normal;
                }
            }
            &:nth-child(1) a {
                width: 50%;
                height: 100%;
                background-color: #000;
                & > span {
                    left: 13%;
                }
                .ca-content {
                    text-align: left;
                    padding-left: 10px;
                    padding-bottom: 10px;
                }
                &:hover {
                    .ca-icon {
                        top: -1%;
                        left: -6%;
                        -webkit-transform: rotate(20deg);
                        -moz-transform: rotate(20deg);
                        -ms-transform: rotate(20deg);
                        transform: rotate(20deg);
                    }
                }
            }
            &:nth-child(2) a {
                width: 33.3%;
                height: 70%;
                background-color: #151515;
                & > span {
                    left: 20%;
                }
                .ca-content {
                    text-align: left;
                    padding-left: 10px;
                    padding-bottom: 10px;
                }
                &:hover {
                    .ca-icon {
                        top: 6%;
                        left: -7%;
                        -webkit-transform: rotate(20deg);
                        -moz-transform: rotate(20deg);
                        -ms-transform: rotate(20deg);
                        transform: rotate(20deg);
                    }
                }
            }
            &:nth-child(3) a {
                width: 16.66666%;
                height: 40%;
                background-color: #272727;
                & > span {
                    left: 39%;
                }
                .ca-content {
                    text-align: left;
                    padding-left: 10px;
                    padding-bottom: 10px;
                }
                &:hover {
                    .ca-icon {
                        top: -30%;
                        left: -40%;
                        -webkit-transform: rotate(20deg);
                        -moz-transform: rotate(20deg);
                        -ms-transform: rotate(20deg);
                        transform: rotate(20deg);
                    }
                }
                .ca-main {
                    @include MQ(0, 820) {
                        font-size: 0.8rem;
                        top: 10px;
                    }
                    @include MQ(0, 670) {
                        font-size: 0.6rem;
                        top: 10px;
                    }
                }
                .ca-sub {
                    @include MQ(0, 820) {
                        font-size: 0.8rem;
                    }
                    @include MQ(0, 670) {
                        font-size: 0.6rem;
                    }
                    @include MQ(0, 520) {
                        font-size: 0.55rem;
                    }
                }
            }
            &:nth-child(4) a {
                width: 16.66666%;
                height: 40%;
                background-color: #272727;
                z-index: 2;
                align-items: flex-end;
                & > span {
                    right: 39%;
                }
                .ca-content {
                    text-align: right;
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
                &:hover {
                    .ca-icon {
                        font-size: 4rem;
                        top: -5%;
                        right: -10%;
                        -webkit-transform: rotate(-20deg);
                        -moz-transform: rotate-(20deg);
                        -ms-transform: rotate(-20deg);
                        transform: rotate(-20deg);
                    }
                }
                .ca-main {
                    @include MQ(0, 820) {
                        font-size: 0.8rem;
                        top: 10px;
                    }
                    @include MQ(0, 670) {
                        font-size: 0.6rem;
                        top: 10px;
                    }
                }
                .ca-sub {
                    @include MQ(0, 820) {
                        font-size: 0.8rem;
                    }
                    @include MQ(0, 670) {
                        font-size: 0.6rem;
                    }
                    @include MQ(0, 520) {
                        font-size: 0.55rem;
                    }
                }
            }
            &:nth-child(5) a {
                width: 33.3%;
                height: 70%;
                background-color: #151515;
                right: 16.66666%;
                z-index: 1;
                align-items: flex-end;
                & > span {
                    right: 20%;
                }
                .ca-content {
                    text-align: right;
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
                &:hover {
                    .ca-icon {
                        font-size: 8rem;
                        top: -1%;
                        right: -8%;
                        -webkit-transform: rotate(-20deg);
                        -moz-transform: rotate-(20deg);
                        -ms-transform: rotate(-20deg);
                        transform: rotate(-20deg);
                    }
                }
            }
            &:nth-child(6) a {
                width: 50%;
                height: 100%;
                background-color: #000;
                right: 0;
                align-items: flex-end;
                & > span {
                    right: 13%;
                }
                .ca-content {
                    text-align: right;
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
                &:hover {
                    .ca-icon {
                        font-size: 8rem;
                        top: 3%;
                        right: -7%;
                        -webkit-transform: rotate(-20deg);
                        -moz-transform: rotate-(20deg);
                        -ms-transform: rotate(-20deg);
                        transform: rotate(-20deg);
                    }
                }
            }
        }
    }
    @include MQ($lg, 0) {
        flex-direction: column;
        li {
            min-height: 70px;
        }
        a {
            flex-direction: row;
            display: flex;
            background: #000;
            color: #efefef;
            justify-content: flex-end;
            position: relative;
            overflow: hidden;
            -webkit-transition: all 300ms linear;
            -moz-transition: all 300ms linear;
            -o-transition: all 300ms linear;
            -ms-transition: all 300ms linear;
            transition: all 300ms linear;
            &:hover {
                background: #4888ac !important;
                color: #000;
                span.ca-icon {
                    -webkit-transition: all 300ms linear;
                    -moz-transition: all 300ms linear;
                    -o-transition: all 300ms linear;
                    -ms-transition: all 300ms linear;
                    transition: all 300ms linear;
                    color: #000;
                    font-size: 120px;
                    opacity: 0.8;
                    position: absolute;
                    top: 10%;
                    -webkit-transform: rotate(20deg);
                    -moz-transform: rotate(20deg);
                    -ms-transform: rotate(20deg);
                    transform: rotate(20deg);
                }
                .ca-sub {
                    color: #191919;
                    opacity: 1;
                }
            }
            & > span {
                width: 40%;
                -webkit-transition: all 300ms linear;
                -moz-transition: all 300ms linear;
                -o-transition: all 300ms linear;
                -ms-transition: all 300ms linear;
                transition: all 300ms linear;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'WebSymbolsLigaRegular', cursive;
                font-size: 1.5rem;
                position: absolute;
                left: 0;
                height: 100%;
            }
            & > div {
                width: 60%;
                display: flex;
                flex-direction: column;
                text-align: left;
                justify-content: center;
            }
            .ca-main {
                font-weight: bold;
                position: relative;
                top: 5px;
                line-height: 2rem;
                font-size: 1rem;
                @include MQ(0, 1150) {
                    font-size: 0.9rem;
                }
                @include MQ(0, 1030) {
                    font-size: 0.8rem;
                }
            }
            .ca-sub {
                color: #cecece;
                font-size: 1rem;
                font-weight: normal;
                opacity: 0.8;
                line-height: normal;
                font-size: 0.9rem;
                @include MQ(0, 1150) {
                    font-size: 0.8rem;
                }
                @include MQ(0, 1030) {
                    font-size: 0.7rem;
                }
            }
        }
    }
}

button#btnMobile {
    display: none;
    background: #000;
    border: none;
    width: 100%;
    height: 45px;
    text-indent: -900em;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    outline: none;
    float: right;
    margin-bottom: 4px;
    border-radius: 3px;
    border-top-right-radius: 0;
    &:after {
        content: '';
        position: relative;
        width: 30px;
        height: 5px;
        background: #4888ac;
        top: -10px;
        float: left;
        left: calc(50% - 15px);
        box-shadow:
            0 10px 0 #4888ac,
            0 20px 0 #4888ac;
    }
    @include MQ(0, $lg) {
        display: block;
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ LINKS ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.roll-link {
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    -ms-perspective: 600px;
    perspective: 600px;
    -webkit-perspective-origin: 50% 50%;
    -moz-perspective-origin: 50% 50%;
    -ms-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;
}

.roll-link:hover {
    text-decoration: none;
}

a:-webkit-any-link {
    text-decoration: none !important;
}

/*removes all link decoration in chrome*/

.roll-link span {
    display: block;
    position: relative;
    padding: 0 2px;
    color: #4888ac;
    text-decoration: none;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    transition: all 400ms ease;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.roll-link:hover span {
    background: #dd4d42;
    -webkit-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
    -moz-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
    -ms-transform: translate3d(0px, 0px, -30px) rotateX(90deg);
    transform: translate3d(0px, 0px, -30px) rotateX(90deg);
}

.roll-link span:after {
    content: attr(data-title);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 2px;
    color: #fff;
    background: #dd4d42;
    -webkit-transform-origin: 50% 0%;
    -moz-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
    -moz-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
    -ms-transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
    transform: translate3d(0px, 105%, 0px) rotateX(-90deg);
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ PAGES ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.no-border {
    .name div {
        border-bottom-left-radius: 0;
    }
}

.image-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 10%;
    top: 0;
    color: #fff;
    width: 100%;
    font-size: 2.3rem;
    padding-top: 25px;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    @include MQ(0, $lg) {
        left: 0;
        font-size: 1.8rem;
    }
}

figure {
    height: 300px;
    position: relative;
    left: -20%;
    width: 123%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    @include MQ(0, $lg) {
        left: -5%;
        width: 111%;
        background-size: auto;
        height: 200px;
    }
    span {
        position: relative;
        margin-bottom: 20px;
        &:nth-child(1) {
            padding-left: 10%;
            font-weight: 700;
        }
        &:nth-child(2) {
            padding-left: 35%;
            font-family: 'Italianno', cursive;
            font-size: 5rem;
            letter-spacing: 0.3rem;
            @include MQ(0, $lg) {
                font-size: 4rem;
            }
        }
        &:nth-child(3) {
            padding-left: 50%;
            font-weight: 700;
        }
    }
}

/* Modal */

.react-modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.32);

    z-index: 2;
}

.react-modal-content {
    width: 100%;
    max-height: 95vh;
    overflow-y: auto;
    z-index: 2;
    /* max-width: 576px; */
    background: #262626;
    padding: 1.5rem;
    position: relative;
    border-radius: 4px;
    box-shadow:
        0 11px 15px -7px rgba(0, 0, 0, 0.2),
        0 24px 38px 3px rgba(0, 0, 0, 0.14),
        0 9px 46px 8px rgba(0, 0, 0, 012);
    border: 0 none;
}

.avoidScroll {
    overflow: hidden;
}

.cookies {
    img {
        width: 150px;
        margin-right: 20px;
        border-radius: 10px;
    }
    button {
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ INDEX ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.who-i-am {
    position: absolute;
    left: 0px;
    font-weight: bold;
    font-size: 1.5rem;
    top: -35px;
    color: #36474f;
    span {
        color: #4888ac;
        font-size: 2rem;
    }
}

.about-description {
    border-radius: 3px;
    padding: 10px;
    background: rgba(51, 51, 51, 0.07);
    border: 1px solid rgba(58, 58, 58, 0.27);
    position: relative;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
    &:before {
        content: '';
        display: block;
        width: 50%;
        height: 50%;
        position: absolute;
        left: -10px;
        bottom: -10px;
        border-bottom: 1px solid #3a3a3a;
        border-left: 1px solid #3a3a3a;
        border-radius: 2px;
    }
    &:after {
        content: '';
        display: block;
        width: 50%;
        height: 50%;
        position: absolute;
        right: -10px;
        top: -10px;
        border-top: 1px solid #3a3a3a;
        border-right: 1px solid #3a3a3a;
        border-radius: 2px;
    }
    p {
        font-style: italic;
        font-size: 1.1rem;
        line-height: 1.4rem;
        font-family: 'Roboto', sans-serif;
    }
    img {
        float: left;
        margin-left: -10px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 0px;
        width: 20%;
        min-width: 100px;
    }
    .insight {
        background-color: #ffffffa6;
        position: absolute;
        right: 0;
        padding: 10px;
        box-shadow:
            0 3px 6px rgba(0, 0, 0, 0.16),
            0 3px 6px rgba(0, 0, 0, 0.23);
    }
}

main {
    .title {
        border-bottom: 1px black solid;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 1px;
        display: flex;
        h2 {
            padding: 5px;
            background-color: black;
            color: white;
            font-weight: bold;
            margin: 0;
            font-size: 1.2rem;
            position: relative;
            top: 1px;
        }
    }
}

.article {
    figure {
        width: 100%;
        min-width: 30px;
        width: 100%;
        min-width: 30px;
        height: auto;
        left: 0;
        position: inherit;
        img {
            width: 100%;
            margin: 0;
            padding: 0;
            display: flex;
            opacity: 0.9;
        }
    }
    .content {
        padding: 5%;
        border-radius: 3px;
        background: rgba(172, 172, 172, 0.07);
        border: 1px solid rgba(58, 58, 58, 0.27);
        h3 {
            color: #577590;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 1.8rem;
        }
        img {
            float: left;
            margin-right: 30px;
        }
    }
    p {
        line-height: 1.6rem;
        font-size: 1.2rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        margin: 0;
        padding-bottom: 15px;
        padding-top: 15px;
    }
    @media only screen and (max-width: 710px) {
        p {
            font-size: 1rem;
            line-height: 1.4em;
        }
    }
    #af_background1,
    #af_background2 {
        fill: #4888ac !important;
    }
    #af_feather {
        fill: #ffda70 !important;
    }
    #af_lines {
        fill: #333 !important;
    }
    .article-thumb {
        float: left;
        padding-left: 15px;
        width: 100%;
        max-width: 70px;
        min-width: 70px;
        height: auto;
        margin-right: 20px;
    }
    .panel-collapse {
        max-height: 1400px;
        overflow: hidden;
        transition: max-height 0.5s ease;
    }
    .panel-close {
        max-height: 30px;
        overflow: hidden;
        transition: max-height 0.5s ease;
    }
    .buttonCollapse {
        border: 0;
        outline: 0;
        background: transparent;
        cursor: pointer;
        margin-top: 30px;
        color: #577590;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 1.1rem;
        &:hover {
            text-decoration: underline;
        }
        h1 {
            font-weight: 500;
            font-size: 1.1rem;
        }
    }
}

.article-firstLetter {
    float: left;
    margin-right: 7px;
    margin-top: 7px;
    font-size: 2.5rem;
    position: relative;
    margin-left: 10px;
    &:after {
        content: '';
        background-color: rgba(128, 128, 128, 0.5);
        display: block;
        width: 120%;
        height: 80px;
        position: absolute;
        top: -130%;
        z-index: -1;
        left: -50%;
    }
}

.article-personal-question {
    font-weight: bold;
    font-size: 150% !important;
    text-align: center !important;
}

.nav-toggle {
    color: #000000;
    text-decoration: none;
    border: 0;
    cursor: pointer;
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ SKILLS ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.skills {
    figure {
        background-image: url(../assets/images/skillsExperiences.jpg);
    }
    .skill {
        display: flex;
        flex-direction: column;
        & > div {
            display: flex;
            align-items: center;
            font-style: italic;
            font-weight: 400;
            margin-top: 10px;
            min-height: 80px;
            & > div {
                width: 50px;
                height: 50px;
                position: relative;
                margin-right: 30px;
                &:before {
                    content: '';
                    background: transparent;
                    border: 1px dashed #0d1b5c;
                    display: block;
                    position: absolute;
                    width: 70px;
                    height: 70px;
                    border-radius: 100%;
                    top: -8px;
                    left: -5px;
                    z-index: -1;
                }
                &:after {
                    content: '';
                    background: #c7d5df;
                    display: block;
                    position: absolute;
                    width: 55px;
                    height: 55px;
                    border-radius: 100%;
                    top: 0;
                    left: 3px;
                    z-index: -1;
                }
            }
            svg {
                display: flex;
                align-items: center;
                margin-left: 8px;
                margin-right: 10px;
                font-size: 2rem;
                width: 50px;
                height: 50px;
                margin-top: 1px;
            }
            span {
                font-weight: 700;
                font-style: normal;
                padding-right: 2px;
            }
        }
    }
    .experiences {
        margin-top: 10px;
        background-color: rgba(51, 51, 51, 0.09);
        position: relative;
        &:before {
            border-top: 2px solid;
            border-left: 2px solid;
            left: -2px;
            top: -2px;
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
        }
        &:after {
            border-bottom: 2px solid;
            border-right: 2px solid;
            right: -2px;
            bottom: -2px;
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
        }
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ PROFESSIONAL ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.professional {
    figure {
        background-image: url(../assets/images/professionaTimeline.jpg);
    }
    .history {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        position: relative;

        img {
            width: 100%;
            display: block;
        }

        .image {
            width: 33%;
            float: left;
        }

        .box {
            border: 1px solid black;
            box-shadow: 0 1px 30px rgba(0, 0, 0, 0.5);
            background: rgba(255, 255, 255, 0.1);
        }

        .dates {
            width: 100%;
            position: relative;
            border-bottom: 2px dotted black;
            z-index: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            .date {
                font-weight: bold;
                color: #000;
                background-color: #4888ac;
                border: 2px solid #001;
                box-shadow: 0 1px 30px rgba(0, 0, 0, 0.5);
                margin: 0 10px;
                position: relative;
                top: 18px;
                padding: 5px 10px;
            }
        }
    }

    .left {
        flex: 1;
    }

    .right {
        flex-direction: row;

        .dates {
            justify-content: flex-start;
        }

        .line {
            border-right: 0;
            border-left: 1px dotted black;
        }

        .post {
            justify-content: flex-start;
        }
    }

    .line {
        border-right: 1px dotted black;
        height: 2em;
        position: relative;
        margin-left: 1.85em;
        margin-right: 50px;
    }

    .post {
        justify-content: flex-end;
        display: flex;

        span {
            border: 1px solid black;
            position: relative;
            line-height: 1.3em;
            text-align: center;
            padding: 5px;
            background-color: rgba(51, 51, 51, 0.09);
        }
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ HOBBIES ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.hobbies {
    figure {
        background-image: url(../assets/images/hobbiesInterests.jpg);
    }
    .hobbiesInterestsFlex {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        color: #fff;
        .hobbiesInterests,
        .hobbiesInterests-min {
            padding: 10px;
            background-color: #4888ac;
        }
        .hobbiesInterests {
            display: flex;
            flex-wrap: wrap;
            flex-basis: 70%;
            align-items: center;
            margin-right: 5px;
            box-shadow: 0px 11px 20px -4px rgb(122, 122, 122);
            img {
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }
            h1 {
                font-weight: 700;
            }
            p {
                margin: 0;
            }
            ul {
                list-style-type: circle;
                margin-left: 20px;
            }
        }
        .hobbiesInterests-min {
            flex-basis: 30%;
            box-shadow: 0px 11px 20px -4px rgb(122, 122, 122);
            p {
                background: #000;
                padding: 3px;
                float: left;
                margin: 1px;
                font-size: 90%;
                line-height: 100%;
                word-break: break-all;
                color: #fff;
            }
        }
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ PROJECTS AND RELEASES ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.projects {
    figure {
        background-image: url(../assets/images/projectsReleases.jpg);
    }
    .projectsReleases {
        padding: 5px;
        -webkit-box-shadow: 0 7px 10px -5px rgba(122, 122, 122, 1);
        -moz-box-shadow: 0 7px 10px -5px rgba(122, 122, 122, 1);
        box-shadow: 0 7px 10px -5px rgba(122, 122, 122, 1);
        line-height: 120%;
        background-color: rgba(52, 70, 93, 0.3);
        color: #000000;
        margin-top: 15px;
        position: relative;
        display: flex;

        &:before {
            border-top: 2px solid;
            border-left: 2px solid;
            left: -2px;
            top: -2px;
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
            border-color: #333;
        }
        &:after {
            border-bottom: 2px solid;
            border-right: 2px solid;
            right: -2px;
            bottom: -2px;
            content: '';
            position: absolute;
            height: 20px;
            width: 20px;
            border-color: #333;
        }

        h1 {
            font-size: 1rem;
            font-weight: bold;
            margin: 5px;
            transform: rotate(180deg);
            writing-mode: vertical-rl;
            text-align: center;
            transform-origin: center;
        }

        div {
            padding: 5px;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        span {
            font-size: 0.8rem;
            border-bottom: 1px solid #848484;
        }

        p {
            padding: 0;
            margin: 0;
            padding-top: 10px;
            font-size: 0.9rem;
        }

        & > .italic {
            font-style: italic;
        }

        &:last-child {
            padding: 0px 20px 10px 20px;
        }
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ CONTACT ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.contact {
    figure {
        background-image: url(../assets/images/contactsAudience.jpg);
    }
    form {
        margin-top: 20px;
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;

        @include MQ(0, $lg) {
            width: 95%;
        }

        .field {
            margin-top: 10px;
            display: flex;
            flex-direction: row;

            @include MQ(0, $lg) {
                flex-direction: column;

                label {
                    padding: 0;
                    justify-content: flex-start !important;
                    margin-bottom: 10px;
                }
            }

            label {
                width: 20%;
                padding-right: 20px;
                align-items: center;
                display: flex;
                justify-content: flex-end;
            }

            & > div {
                width: 100%;
            }
        }
    }
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ FOOTER ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

footer {
    width: 100%;
    color: #d7d8da;
    background-color: #33363b;
    position: relative;
    margin-top: 30px;
    .art {
        position: absolute;
        left: 50%;
        bottom: 0;
        img {
            max-width: 1440px;
            left: -50%;
            position: relative;
            bottom: 0;
            display: block;
        }
    }
    .container {
        display: flex;
        flex-direction: column;
        .wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            #insights {
                margin-top: 10px;
                padding-left: 10%;
                width: 50%;
                font-family: Georgia, Times, serif;
                .title {
                    font-weight: bold;
                    font-size: 1rem;
                    border-left: 5px solid white;
                    padding-left: 4%;
                }
                .phrase {
                    margin-top: 5px;
                    font-style: italic;
                    padding-left: 4%;
                }
                .author {
                    text-align: right;
                    font-size: 0.7rem;
                }
                hr {
                    border: 0;
                    height: 1px;
                    margin-top: 10px;
                    margin-bottom: 0;
                    background-image: -webkit-linear-gradient(
                        left,
                        rgba(0, 0, 0, 0),
                        rgba(255, 255, 255, 0.75),
                        rgba(0, 0, 0, 0)
                    );
                    background-image: -moz-linear-gradient(
                        left,
                        rgba(0, 0, 0, 0),
                        rgba(0, 0, 0, 0.75),
                        rgba(0, 0, 0, 0)
                    );
                    background-image: -ms-linear-gradient(
                        left,
                        rgba(0, 0, 0, 0),
                        rgba(0, 0, 0, 0.75),
                        rgba(0, 0, 0, 0)
                    );
                    background-image: -o-linear-gradient(
                        left,
                        rgba(0, 0, 0, 0),
                        rgba(0, 0, 0, 0.75),
                        rgba(0, 0, 0, 0)
                    );
                }
            }
            .social {
                margin-top: 5px;
                a {
                    color: #c1c1c1;
                    padding: 10px;
                    position: relative;
                    z-index: 1;
                    transition: all 0.2s;
                    &:before {
                        transition: all 0.2s;
                        content: '';
                        background: transparent;
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-radius: 100%;
                        top: 50%;
                        left: 50%;
                        z-index: -1;
                    }
                    &:hover {
                        color: #fff;
                        &:before {
                            width: 60px;
                            height: 60px;
                            top: 3px;
                            left: 0px;
                        }
                    }
                    &.instagram {
                        &:hover {
                            &:before {
                                background: #c13584;
                            }
                        }
                    }
                    &.facebook {
                        &:hover {
                            &:before {
                                background: #4267b2;
                            }
                        }
                    }
                    &.linkedin {
                        &:hover {
                            &:before {
                                background: #2867b2;
                            }
                        }
                    }
                }

                button {
                    color: #c1c1c1;
                    padding: 10px;
                    position: relative;
                    z-index: 1;
                    transition: all 0.2s;
                    font-size: 2.5rem;
                    &:before {
                        transition: all 0.2s;
                        content: '';
                        background: transparent;
                        display: block;
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-radius: 100%;
                        top: 50%;
                        left: 50%;
                        z-index: -1;
                    }
                    &:hover {
                        color: #fff;
                        &:before {
                            width: 60px;
                            height: 60px;
                            top: 3px;
                            left: 0px;
                        }
                    }
                    &.instagram {
                        &:hover {
                            &:before {
                                background: #c13584;
                            }
                        }
                    }
                    &.facebook {
                        &:hover {
                            &:before {
                                background: #4267b2;
                            }
                        }
                    }
                    &.linkedin {
                        &:hover {
                            &:before {
                                background: #2867b2;
                            }
                        }
                    }
                }
            }
        }
    }
    .notepad {
        font-size: 0.9rem;
        display: flex;
        justify-content: space-between;
        align-content: center;

        .roll-link {
            font-size: 0.9rem;
            position: relative;
            top: 2px;
        }

        button.cookie {
            align-items: center;
            justify-content: center;
            display: flex;
            font-size: 0.8rem;
            opacity: 0.5;

            &:hover {
                color: #2867b2;
            }

            svg {
                margin-right: 5px;
            }
        }
    }
    .credits {
        text-align: center;
        font-size: 0.85rem;
        padding-bottom: 10px;
        padding-top: 35px;
        span {
            display: block;
            line-height: initial;
        }
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

@-webkit-keyframes fadeInLeftRight {
    from {
        opacity: 0;
        left: -30%;
    }
    to {
        opacity: 1;
        left: 10%;
    }
}

@-moz-keyframes fadeInLeftRight {
    from {
        opacity: 0;
        left: -30%;
    }
    to {
        opacity: 1;
        left: 10%;
    }
}

@keyframes fadeInLeftRight {
    from {
        opacity: 0;
        left: -30%;
    }
    to {
        opacity: 1;
        left: 10%;
    }
}

.animate-phrase {
    opacity: 0;
    -webkit-animation: fadeInLeftRight ease 1;
    -moz-animation: fadeInLeftRight ease 1;
    animation: fadeInLeftRight ease 1;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    animation-duration: 1s;
}

.one {
    -webkit-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

.two {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓ DARK ▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

/*▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓▓*/

.dark {
    background: #1c1c1e;
    transition: all 0.2s;
    color: #b7b7b7;
    .lamp {
        .style4 {
            fill: #404040 !important;
        }
    }
    .logo {
        cursor: pointer;
        .letter {
            border-color: #4888ac;
            background-color: transparent;
            color: #4888ac;
        }
        .letter:last-child {
            background-color: transparent;
            color: #fff;
        }
    }
    .me-desktop {
        filter: brightness(0.9);
    }
    .background-circle,
    .background-square {
        background-color: rgba(73, 136, 172, 0.3) !important;
    }
    .background-border-left-top,
    .background-middle-right {
        opacity: 0.05;
    }
    .about-description {
        background-color: rgba(0, 0, 0, 0.12);
        &:before,
        &:after {
            border-color: #545454;
        }
        .who-i-am {
            color: #fff;
        }
    }
    .insight {
        background: #07080c;
    }
    // Home
    .article {
        figure {
            img {
                opacity: 0.8;
                filter: saturate(0.5);
            }
        }
        #af_lines {
            fill: #5f5f5f !important;
        }
    }
    // Skills
    .skills {
        .skill {
            & > div {
                & > div {
                    &:before {
                        border: 1px dashed #717171;
                    }
                    &:after {
                        background: rgba(73, 136, 172, 0.5) !important;
                    }
                }
            }
        }
        .experiences {
            background-color: rgba(255, 255, 255, 0.01);
        }
    }
    // Hobbies
    .hobbies {
        .hobbiesInterestsFlex {
            .hobbiesInterests,
            .hobbiesInterests-min {
                background-color: rgba(73, 136, 172, 0.3) !important;
                box-shadow: 0px 11px 20px -4px rgba(0, 0, 0, 0.15);
            }
        }
    }
    // Projects
    .projectsReleases {
        -webkit-box-shadow: 0 7px 10px -5px #191919;
        -moz-box-shadow: 0 7px 10px -5px #191919;
        box-shadow: 0 7px 10px -5px #191919;
        background-color: rgba(52, 70, 93, 0.3);
        color: inherit;

        &:before {
            border-color: #8c8c8c;
        }
        &:after {
            border-color: #8c8c8c;
        }

        h1 {
            color: #fff;
        }

        span {
            border-color: rgba(255, 255, 255, 0.2);
        }
    }
}
